

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  /* margin-top: 4rem; */
}

.about-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  /* margin-top: 63px; */
  text-align: center;
}

.about-subsection {
  padding: 20px 10px;
  margin: 0px auto;
}

.about-subsection {
  display: flex;
}

.about-subsection > * {
  flex: 1;
  margin-bottom: 30px;
}
.resume-button-container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.about-title {
  font-size: calc(55px + 1rem);
  margin-top: 103px;
  line-height: 1.1;
  color: rgb(255,255,255);
  font-family: Verdana, Rubik, Geneva, Tahoma, sans-serif;
}

.about-title-p {
  font-size: calc(25px + 0.4rem);
  line-height: 40px;
  color: rgb(255,255,255);
}

.subTitleWork {
  font-size: calc(30px + 0.4rem);
  font-weight: bold;
  line-height: 40px;
  color: rgb(255,255,255);
}

.WIP-title {
  color: rgb(180,180,180);
  font-size: calc(10px + 0.5rem);
}

.about-image > img {
  max-width: 100%;
  height: auto;
}

/* Media Query */
@media (max-width: 1380px) {
  .about-title {
    font-size: calc(50px + 1rem);
  }
  .about-title-p {
    font-size: 20px;
  }
  .subTitleWork {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .resume-button-container {
    justify-content: space-around;
  }
  .about-title {
    font-size: calc(30px + 0.8rem);
    text-align: center;
  }
  .about-title-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .subTitleWork {
    font-size: 30px;
  }
  .about-subsection {
    display: block;
  }
}