.project-card {
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    transition: transform 500ms ease;
    border: 1px solid rgba(211, 211, 211, 0.397);
    padding: 1rem;
}

.image-div {
    text-align: center;
    height: 20rem;
}

.project-img {
    max-width: 100%;
    max-height: 350px;
    overflow: hidden;
}

.project-details {
    text-align: center;
}

.project-title {
    color: rgb(0,0,0);
    font-size: calc(20px + 0.1rem);
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 40px;
    height: 50px;
}

.project-description {
    color: rgb(0,0,0);
    font-size: calc(15px + 0.1rem);
    line-height: 1.5rem;
    font-weight: 200;
    height: 110px;
}

ul {
    margin: 0;
    padding: 0;
}

.project-skills-title {
    color: rgb(0,0,0);
    font-size: calc(13px + 0.3rem);
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 15px;
}

.project-icons {
    height: 55px;
    width: auto;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

@media (min-width: 687px) {
    .project-card {
        grid-column: span 2;
        display: flex;
        justify-content: space-between;
        max-height: 25rem;
    }
    .image-div {
        width: 50%;
        height: 100%;
    }
    .project-img {
        max-width: 100%;
        max-height: 100%;
    }
    .project-title {
        height: fit-content;
        margin-bottom: 0.4rem;
    }
    .project-details {
        text-align: left;
        padding: 1rem;
        max-width: 50%;
        height: fit-content;
    }
    .project-title {
        margin-top: 0;
    }
    .project-description {
        margin: 0;
        height: fit-content;
    }
    .project-skills-title {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    }


}

@media (min-width: 1387px) {
    .project-details {
        height: 90%;
    }
    .project-img {
        max-height: 21rem;
    }
    .project-title {
        height: 3.5rem;
    }
    .project-description {
        height: 8rem;
    }
    .project-skills-title {
        margin-top: 2rem;
    }
}