:root {
    --rows: ;
    --columns: ;
    --tilesize: 65;
    --clr-title: rgb(255, 100, 45);
    --clr-title1: rgb(110, 20, 80);
    --clr-title2: rgb(80, 145, 235);
    --clr-title3: rgb(0, 155, 155);
    --clr-blue: #5091eb;
    --clr-gridbackground: #24241f;
    --clr-grid: #009999;
    --clr-gradient1: rgb(220, 30, 80);
    --clr-glassgrad1: rgba(200, 200, 200, 0.8);
    --clr-glassgrad2: rgba(200, 200, 200, 0.1);
    --clr-glassline: rgba(220, 30, 80, 0.2);
    --clr-links: rgb(0, 247, 255);
    --clr-gridshadow: #dd5277;
    --clr-bodybackground: #202020;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    margin: 0;
    padding: 5px 35px;
    background-color: var(--clr-bodybackground);
    width: calc(100% - 70px);
    min-height: 63px;
    border-bottom: 1px solid var(--clr-links);
}
.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
}
.header li a {
    display: block;
    padding: 18px 18px;
    text-decoration: none;
    color: rgb(255,255,255);
}
.header li a:hover {
    background-color: gray;
    cursor: pointer;
}

/* ------- Burger ------- */
.header .nav-menu {
    max-height: 0;
    -webkit-transition: max-height 0.3s ease-out;
    transition: max-height 0.3s ease-out;
}

.header .burger {
    display: block;
    cursor: pointer;
    user-select: none;
    float: right;
    padding: 30px 22px;
    position: relative;
}

.header .burger .bar {
    display: block;
    position: relative;
    width: 25px;
    height: 3px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: var(--clr-links);
}

.header .burger .bar:before,
.header .burger .bar:after {
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: var(--clr-links);
}

.header .burger .bar:before {
    top: 8px;
}

.header .burger .bar:after {
    top: -8px;
}

.header .b-button {
    display: none;
}

.header .b-button:checked ~ .nav-menu {
    max-height: 370px;
}

.header .b-button:checked ~ .burger .bar {
    background: transparent;
}

.header .b-button:checked ~ .burger .bar::before {
    transform: rotate(-45deg);
}

.header .b-button:checked ~ .burger .bar::after {
    transform: rotate(45deg);
}

.header .b-button:checked ~ .burger:not(.steps) .bar:before,
.header .b-button:checked ~ .burger:not(.steps) .bar:after {
    top: 0;
}

@media (min-width: 48em) {
    .header li {
        float: left;
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .header .nav-menu {
        max-height: none;
    }
    .header .burger {
        display: none;
    }
}

@media (max-width: 960px) and (min-width: 786px) {

}