.course-card {
    position: relative;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    transition: transform 500ms ease;
    border: 3px solid rgba(211, 211, 211, 0.397);
}

.course-card:hover {
    transform: scale(1.03);
}

.course-card-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
    height: 10rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 10px;
}

.course-card-role {
    position: absolute;
    background: transparent;
    height: 4rem;
    top: 1.5rem;
    left: 0;
    width: 100%;
    display: flex;
    align-items: top;
    justify-content: center;
}

.course-title-text {
    text-align: center;
    padding: 1rem;
    margin: 0;
    color: rgb(255, 255, 255);
    font-size: calc(20px + 0.3rem);
    text-align: center;
    font-weight: 700;
    text-overflow: ellipsis;
    line-height: 1.25;
}

.course-info {
    padding: 1rem;
}

.course-duration {
    text-align: center;
    color: rgba(0, 0, 0);
    font-size: calc(20px + 0.1rem);
    margin: 0px;
    padding-top: 0.8rem;
    font-weight: 600;
}

.job-description {
    text-align: center;
    max-height: fit-content;
}

/* Media Query */

@media (max-width: 1380px) {

}

@media (max-width: 768px) {

}