.work-card {
    position: relative;
    margin: 0 auto;
    width: 95%;
    max-height: 75rem;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    transition: transform 500ms ease;
    border: 3px solid rgba(211, 211, 211, 0.397);
}

.work-card:hover {
    transform: scale(1.03);
}

.work-card-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7));
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.work-card-role {
    position: absolute;
    background: transparent;
    height: 4rem;
    top: 1rem;
    left: 0;
    width: 100%;
    display: flex;
    align-items: top;
    justify-content: center;
}

.work-info {
    padding: 0.2rem;
    font-size: calc(10px + 0.1rem);
}

.work-title-text {
    text-align: center;
    padding: 0.5rem;
    margin: 0;
    color: rgb(255, 255, 255);
    font-size: calc(20px + 0.2rem);
    text-align: center;
    font-weight: 700;
    text-overflow: ellipsis;
    height: fit-content;
}

.company-title {
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: 700;
    font-size: calc(18px + 0.3rem);
    margin-bottom: 0rem;
    line-height: normal;
    padding-top: 1.5rem;
}

.work-duration {
    text-align: center;
    color: rgba(0, 0, 0);
    font-size: calc(20px + 0.1rem);
    margin: 0px;
    padding-top: 0.2rem;
    font-weight: 600;
}

.job-description {
    text-align: center;
    font-size: calc(13px + 0.08rem);
    margin: 0;
    margin-bottom: 0.4rem;
}

.experience-bullets {
    font-size: 14px;
    padding-bottom: 10px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

/* Media Query */

@media (max-width: 1380px) {
    .work-card {
        min-width: 43vw;
    }
}

@media (max-width: 768px) {
    .work-card {
        width: 90%;
    }
}